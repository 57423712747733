import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { VALIDATION } from 'config/validation'
import { EMAIL_REQUESTED_URL } from 'constants/routes'
import Button from 'components/common/Button'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import TextField from 'components/common/TextField/TextField'
import { useAuthenticateEmail } from 'hooks/api/hub'
import styles from './LoginForm.module.scss'
import LoginFormDisclaimer from './LoginFormDisclaimer'

const LoginForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { authenticateEmail } = useAuthenticateEmail()

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
    },
  })

  const { getValues, handleSubmit } = methods

  const onSubmit = () => {
    const values = getValues()
    const email = values.email
    const encodedURLEmail = encodeURIComponent(email)
    authenticateEmail(email)
    navigate(`${EMAIL_REQUESTED_URL}?email=${encodedURLEmail}`)
  }

  return (
    <FormProvider {...methods}>
      <form
        id="magic-login"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <TextField
            type="email"
            name="email"
            rules={{ required: true, pattern: VALIDATION.LOGIN_EMAIL }}
            labelText={t('components.hub.magic-login.label')}
            placeholder={t('components.hub.magic-login.email-placeholder')}
            customErrorMessage={
              <SetInnerHtmlSafe>
                {t('components.hub.magic-login.email-error', {
                  'error-invalid-prefix': `<b>${t('common.alerts.error-invalid-prefix')}</b>`,
                })}
              </SetInnerHtmlSafe>
            }
          />
        </div>
        <LoginFormDisclaimer />
        <Button
          className={styles.button}
          data-testid="magic-login-button"
          type="submit"
        >
          {t('components.hub.magic-login.button')}
        </Button>
      </form>
    </FormProvider>
  )
}

export default LoginForm
