import { useTranslation } from 'react-i18next'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import styles from './LoginForm.module.scss'

const LoginFormDisclaimer = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.disclaimer}>
      <SetInnerHtmlSafe>
        {t('components.hub.magic-login.disclaimer.text', {
          'privacy-policy-link': `<a class="${styles.link}" href="${t('components.hub.magic-login.disclaimer.privacy-policy-link')}" target="_blank" rel="noopener noreferrer">${t('components.hub.magic-login.disclaimer.privacy-policy-text')}</a>`,
          'eula-link': `<a class="${styles.link}" href="${t('components.hub.magic-login.disclaimer.eula-link')}" target="_blank" rel="noopener noreferrer">${t('components.hub.magic-login.disclaimer.eula-text')}</a>`,
        })}
      </SetInnerHtmlSafe>
    </div>
  )
}

export default LoginFormDisclaimer
