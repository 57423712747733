import { memo } from 'react'
import { type ReactElement, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { MdEvent, MdOutlineStorefront } from 'react-icons/md'
import { type Options } from 'react-select'
import { DateTime } from 'luxon'
import Mixpanel from 'services/analytics/Mixpanel'
import Button from 'components/common/Button'
import Card from 'components/common/Card'
import DateRange from 'components/common/DateRange'
import Select from 'components/common/Select'
import { useImpactData, usePrepareImpactDataCsv } from 'hooks/api/hub'
import styles from './FilterControls.module.scss'
import FilterSelection from './FilterSelection'

export type FilterOption = {
  value: string
  label: string | ReactElement
}

export const allStoresOption: FilterOption = {
  value: 'all-sites',
  label: <Trans i18nKey="components.filter-controls.all-sites-label" />,
}

const FilterControls = ({
  allStoresForSelectedBusiness,
  selectedStores,
  setSelectedStores,
  selectedDateRange,
  setSelectedDateRange,
  initialDateRange,
}) => {
  const { t } = useTranslation()

  const storesFilterOptions: Options<FilterOption> = useMemo(() => {
    let storeOptions = []
    if (allStoresForSelectedBusiness) {
      storeOptions = allStoresForSelectedBusiness
        ?.map((store) => ({
          value: String(store.id),
          label: store.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    }

    if (selectedStores && selectedStores.some((v) => v.value === allStoresOption.value)) {
      return storeOptions
    }

    if (allStoresForSelectedBusiness.length === 1) {
      return storeOptions
    }

    return [allStoresOption, ...storeOptions]
  }, [selectedStores, allStoresForSelectedBusiness])

  useEffect(() => {
    if (allStoresForSelectedBusiness && storesFilterOptions) {
      if (allStoresForSelectedBusiness.length === 1) {
        setSelectedStores([storesFilterOptions[0]])
      } else {
        setSelectedStores([allStoresOption])
      }
    }

    setTimeout(() => {
      fetchImpactData()
    }, 100)
  }, [allStoresForSelectedBusiness])

  const isAllSitesSelected = useMemo(() => {
    if (Array.isArray(selectedStores)) {
      return selectedStores.some((v) => v.value === allStoresOption.value)
    } else {
      return false
    }
  }, [selectedStores])

  const selectedStoreIds = useMemo(
    () =>
      isAllSitesSelected
        ? storesFilterOptions?.map((s) => Number(s.value))
        : selectedStores?.map((s) => Number(s.value)),
    [isAllSitesSelected, storesFilterOptions, selectedStores],
  )
  const startDate = DateTime.fromJSDate(selectedDateRange[0]).toFormat('yyyy-MM-dd')
  const endDate = DateTime.fromJSDate(selectedDateRange[1]).toFormat('yyyy-MM-dd')
  const { fetchImpactData, dataUpdatedAt } = useImpactData({
    storeIds: selectedStoreIds,
    startDate,
    endDate,
  })
  usePrepareImpactDataCsv({
    storeIds: selectedStoreIds,
    startDate,
    endDate,
  })

  const [hasExecutedInitialFetch, setHasExecutedInitialFetch] = useState(false)
  useEffect(() => {
    if (selectedStoreIds && !hasExecutedInitialFetch) {
      fetchImpactData()
      setHasExecutedInitialFetch(true)
    }
  }, [selectedStoreIds])

  const handleSelectedStoresChange = (values) => {
    let valuesToSet = values
    if (!Array.isArray(values)) {
      valuesToSet = [values]
    }

    if (values.length >= 2 && values.some((v) => v.value === allStoresOption.value)) {
      valuesToSet = valuesToSet.filter((v) => v.value === allStoresOption.value)
    }

    setSelectedStores(valuesToSet)
  }

  const differenceInDays = useMemo(() => {
    const start = DateTime.fromJSDate(selectedDateRange[0])
    const end = DateTime.fromJSDate(selectedDateRange[1])
    const diff = start.diff(end, ['days']).toObject().days
    const roundedDiff = Math.round(Math.abs(diff))
    return roundedDiff
  }, [selectedDateRange])

  const zone = DateTime.local().zoneName

  const handleApplyClick = () => {
    fetchImpactData()
    Mixpanel.track('PARTNER_CALENDAR_DATE', {
      day_count: differenceInDays,
      start_date: DateTime.fromJSDate(selectedDateRange[0], { zone }).toFormat('yyyy-MM-dd'),
      end_date: DateTime.fromJSDate(selectedDateRange[1], { zone }).toFormat('yyyy-MM-dd'),
    })
  }

  const handleCancelClick = () => {
    setSelectedStores([allStoresOption])
    setSelectedDateRange(initialDateRange)
    setTimeout(() => {
      fetchImpactData()
    }, 100)
  }

  const resetDateRange = () => {
    setSelectedDateRange(initialDateRange)
    setTimeout(() => {
      fetchImpactData()
    }, 100)
  }

  const removeSelectedStore = (storeId) => {
    const updatedStoreSelection = selectedStores.filter((s) => Number(s.value) !== Number(storeId))
    if (updatedStoreSelection.length === 0) {
      setSelectedStores([allStoresOption])
    } else {
      setSelectedStores(updatedStoreSelection)
    }
    setTimeout(() => {
      fetchImpactData()
    }, 100)
  }

  return (
    <>
      <Card className={styles.filterWrapper}>
        <h2>{t('components.filter-controls.title')}</h2>
        <section className={styles.filters}>
          <div>
            <div className={styles.dropdownLabel}>
              <MdOutlineStorefront />
              <label htmlFor="stores">{t('components.filter-controls.sites')}</label>
            </div>
            <div
              className={styles.storesDropdownContainer}
              data-testid="store-filter-dropdown"
            >
              <Select
                name="stores"
                options={storesFilterOptions}
                isMulti={!isAllSitesSelected}
                onChange={handleSelectedStoresChange}
                value={selectedStores}
                onMenuOpen={() => Mixpanel.track('PARTNER_IMPACT_DATA_FILTER_STORES_OPEN')}
              />
            </div>
          </div>
          <div>
            <div className={styles.dropdownLabel}>
              <MdEvent />
              <label>{t('components.filter-controls.date-range')}</label>
            </div>
            <DateRange
              dateRange={selectedDateRange}
              setDateRange={(value) => setSelectedDateRange(value)}
            />
          </div>
        </section>
        <div className={styles.buttonWrapper}>
          <Button
            variant="no-button"
            className={styles.cancelButton}
            onClick={handleCancelClick}
          >
            {t('components.filter-controls.cancel')}
          </Button>
          <Button
            variant="no-button"
            className={styles.applyButton}
            onClick={handleApplyClick}
          >
            {t('components.filter-controls.apply')}
          </Button>
        </div>
      </Card>

      {(selectedStores || selectedDateRange) && (
        <FilterSelection
          selectedStores={selectedStores}
          selectedDateRange={selectedDateRange}
          initialDateRange={initialDateRange}
          dataUpdatedAt={dataUpdatedAt}
          resetDateRange={resetDateRange}
          removeSelectedStore={removeSelectedStore}
        />
      )}
    </>
  )
}

export default memo(FilterControls)
