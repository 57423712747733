import { MdExpandMore } from 'react-icons/md'
import ReactSelect, { type DropdownIndicatorProps, components } from 'react-select'
import styles from './Select.module.scss'

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator
      {...props}
      className={styles.dropdownIndicator}
    >
      <MdExpandMore />
    </components.DropdownIndicator>
  )
}

const Select = ({ options, isMulti = false, onChange, value, name, onMenuOpen = undefined }) => {
  return (
    <ReactSelect
      name={name}
      inputId={name}
      className={styles.select}
      options={options}
      isMulti={isMulti}
      onChange={onChange}
      isClearable={false}
      value={value}
      components={{ DropdownIndicator }}
      isSearchable={isMulti}
      onMenuOpen={onMenuOpen}
      styles={{
        container: (provided) => ({
          ...provided,
          minHeight: '40px',
        }),
        control: (provided) => ({
          ...provided,
          borderColor: '#b7b7b7',
          minHeight: '40px',
        }),
        multiValue: (styles) => {
          return {
            ...styles,
            backgroundColor: '#f3f1f4',
          }
        },
        multiValueLabel: (styles) => ({
          ...styles,
          color: '#3b1869',
          fontWeight: 500,
          fontSize: '14px',
        }),
      }}
    />
  )
}

export default Select
