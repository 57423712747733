import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdEvent, MdOutlineStorefront } from 'react-icons/md'
import { DateTime } from 'luxon'
import Pill from 'components/common/Pill'
import { allStoresOption } from '../FilterControls'
import styles from './FilterSelection.module.scss'

type Props = {
  selectedStores: { label: string; value: string }[]
  selectedDateRange: [Date, Date]
  initialDateRange?: [Date, Date]
  dataUpdatedAt?: number
  resetDateRange?: () => void
  removeSelectedStore?: (storeId: string) => void
  pdfDisplayMode?: boolean
}

const FilterSelection = ({
  selectedStores,
  selectedDateRange,
  initialDateRange,
  dataUpdatedAt,
  resetDateRange,
  removeSelectedStore,
  pdfDisplayMode = false,
}: Props) => {
  const { t } = useTranslation()
  const [stores, setStores] = useState(selectedStores)
  const [startDate, setStartDate] = useState(DateTime.fromJSDate(selectedDateRange[0]))
  const [endDate, setEndDate] = useState(DateTime.fromJSDate(selectedDateRange[1]))

  const isInitialDateRange =
    startDate.toJSDate().getTime() == initialDateRange?.[0].getTime() &&
    endDate.toJSDate().getTime() == initialDateRange?.[1].getTime()

  useEffect(() => {
    setStores(selectedStores)
    setStartDate(DateTime.fromJSDate(selectedDateRange[0]))
    setEndDate(DateTime.fromJSDate(selectedDateRange[1]))
  }, [dataUpdatedAt])

  return (
    <section className={styles.filterSelection}>
      <h4 className={styles.heading}>{t('components.filter-controls.showing-data')}</h4>
      <div className={styles.list}>
        {stores?.map((store) => {
          if (store.value === allStoresOption.value) {
            return (
              <Pill
                size="small"
                showBorder
                key={store.value}
                data-testid="all-sites-option"
              >
                <Pill.Icon>
                  <MdOutlineStorefront />
                </Pill.Icon>
                <Pill.Text>{allStoresOption.label}</Pill.Text>
              </Pill>
            )
          } else {
            return (
              <Pill
                size="small"
                showBorder
                key={store.value}
                data-testid="store-filter-pill"
              >
                <Pill.Icon>
                  <MdOutlineStorefront />
                </Pill.Icon>
                <Pill.Text>{store.label}</Pill.Text>
                {!pdfDisplayMode && (
                  <Pill.CloseIcon
                    onClick={() => removeSelectedStore(store.value)}
                    data-testid={`remove-store-${store.value}`}
                  />
                )}
              </Pill>
            )
          }
        })}

        <Pill
          size="small"
          showBorder
        >
          <Pill.Icon>
            <MdEvent />
          </Pill.Icon>
          <Pill.Text>
            {startDate.toFormat('dd/MM/yy')} - {endDate.toFormat('dd/MM/yy')}
          </Pill.Text>
          {!isInitialDateRange && !pdfDisplayMode && (
            <Pill.CloseIcon
              onClick={resetDateRange}
              data-testid="reset-date-range"
            />
          )}
        </Pill>
      </div>
    </section>
  )
}

export default FilterSelection
