import 'react-calendar/dist/Calendar.css'
import { useTranslation } from 'react-i18next'
import { MdExpandMore } from 'react-icons/md'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import { DateTime } from 'luxon'
import Mixpanel from 'services/analytics/Mixpanel'

const DateRange = ({ dateRange, setDateRange }) => {
  const { t } = useTranslation()
  const onChange = (value) => {
    setDateRange(value)
  }

  const maxDate = DateTime.now().minus({ day: 2 }).toJSDate()

  return (
    <DateRangePicker
      dayAriaLabel={t('components.date-range-picker.select-day')}
      onChange={onChange}
      value={dateRange}
      calendarIcon={MdExpandMore}
      clearIcon={null}
      format="dd/MM/yyyy"
      maxDate={maxDate}
      minDate={new Date(2022, 0, 1)}
      rangeDivider="-"
      onCalendarOpen={() => Mixpanel.track('PARTNER_CALENDAR_OPEN')}
    />
  )
}

export default DateRange
