import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const fetchPrepareImpactDataCsv = async ({ storeIds, startDate, endDate }) => {
  const response = await axios.post('/api/v1/store_statistics/csv', {
    start_date: startDate,
    end_date: endDate,
    store_ids: storeIds,
  })
  const blob = new File([response.data], 'impact-data.csv', {
    type: 'text/csv',
  })
  const link = document.createElement('a')
  const exportUrl = URL.createObjectURL(blob)
  link.href = exportUrl

  const filename = response.headers['content-disposition'].match(/filename="([^"]+)"/)
  link.download = filename[1]

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  return response.data
}

type usePrepareImpactDataCsvType = {
  storeIds: number[]
  startDate: string
  endDate: string
}

const usePrepareImpactDataCsv = ({ storeIds, startDate, endDate }: usePrepareImpactDataCsvType) => {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['impactDataCsv'],
    queryFn: () => fetchPrepareImpactDataCsv({ storeIds, startDate, endDate }),
    enabled: false,
    staleTime: Infinity,
  })
}

export default usePrepareImpactDataCsv
