import { lazy, memo, useEffect, useMemo, useState, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { FaFileCsv } from 'react-icons/fa6'
import { type Options } from 'react-select'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelectedBusiness } from 'hooks'
import { DateTime } from 'luxon'
import Mixpanel from 'services/analytics/Mixpanel'
import Button from 'components/common/Button'
import Card from 'components/common/Card'
import LoadingSpinner from 'components/common/LoadingSpinner'
import SceneHeader from 'components/common/SceneHeader'
import TodaysDate from 'components/common/TodaysDate'
import DataGrid from 'components/hub/ImpactData/DataGrid'
import {
  allStoresOption,
  type FilterOption,
} from 'components/hub/ImpactData/FilterControls/FilterControls'
import NoData from 'components/hub/ImpactData/NoData'
import { Spacer } from 'components/layout/primitives'
import { useStores, useBusinesses, usePrepareImpactDataCsv } from 'hooks/api/hub'
import styles from './ImpactDataScene.module.scss'

const DownloadPdfButton = lazy(() => import('components/hub/ImpactData/DownloadPdfButton'))
const FilterControls = lazy(() => import('components/hub/ImpactData/FilterControls'))

const ImpactDataScene = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { stores } = useStores()
  const { selectedBusinessId, selectedBusinessStoreIds } = useSelectedBusiness()
  const { businesses } = useBusinesses()
  const { data, isPending: isLoadingImpactData } = useQuery<any>({
    queryKey: ['impactData'],
    queryFn: async () => {
      return queryClient.getQueryData(['impactData']) ?? null
    },
    enabled: false,
  })

  const [selectedStoresFilter, setSelectedStoresFilter] = useState<Options<FilterOption>>()
  const yesterday = useMemo(() => DateTime.now().minus({ day: 1 }).toJSDate(), [])
  const thirtyOneDaysAgo = useMemo(() => DateTime.now().minus({ day: 31 }).toJSDate(), [])
  const initialDateRange: [Date, Date] = [thirtyOneDaysAgo, yesterday]
  const [selectedDateRange, setSelectedDateRange] = useState<any>(initialDateRange)

  const selectedBusinessName = useMemo(() => {
    return businesses?.find((business) => business.id === selectedBusinessId)?.name || ''
  }, [selectedBusinessId, businesses])

  useEffect(() => {
    Mixpanel.track('PARTNER_IMPACT_DATA_PAGE_LANDING')
  }, [])

  const allStoresForSelectedBusiness = useMemo(() => {
    return stores?.filter((store) => selectedBusinessStoreIds.includes(store.id))
  }, [stores, selectedBusinessStoreIds])

  const getStoreIds = () => {
    if (selectedStoresFilter?.some((store) => store.value === allStoresOption.value)) {
      return allStoresForSelectedBusiness?.map((store) => store.id)
    }
    return selectedStoresFilter?.map((store) => Number(store.value)) || []
  }

  const { refetch: downloadCsv } = usePrepareImpactDataCsv({
    storeIds: getStoreIds(),
    startDate: selectedDateRange[0],
    endDate: selectedDateRange[1],
  })

  const handleClickCsvDownload = () => {
    Mixpanel.track('PARTNER_IMPACT_DATA_DOWNLOAD', { format: 'csv' })
    downloadCsv()
  }

  if (allStoresForSelectedBusiness?.length <= 0) {
    return <NoData />
  }

  return (
    <>
      <TodaysDate />
      <div className={styles.header}>
        <div>
          <SceneHeader
            title={t('scenes.hub.impact-data.title')}
            description={t('scenes.hub.impact-data.description')}
          />
        </div>

        {!isLoadingImpactData && (
          <Suspense>
            <DownloadPdfButton
              data={data}
              selectedStores={selectedStoresFilter}
              selectedDateRange={selectedDateRange}
              selectedBusinessName={selectedBusinessName}
            />
          </Suspense>
        )}
      </div>
      <Spacer height="2rem" />
      {allStoresForSelectedBusiness?.length > 0 && (
        <Suspense fallback={<LoadingSpinner />}>
          <FilterControls
            allStoresForSelectedBusiness={allStoresForSelectedBusiness}
            selectedStores={selectedStoresFilter}
            setSelectedStores={setSelectedStoresFilter}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            initialDateRange={initialDateRange}
          />
        </Suspense>
      )}
      {isLoadingImpactData ? (
        <LoadingSpinner containerHeight="250px" />
      ) : (
        <>
          <div>
            <DataGrid data={data} />
          </div>
          <div className={styles.cardWrapper}>
            <Card className={styles.downloadCard}>
              <h3 className={styles.downloadCardTitle}>
                {t('scene.impact-data.download-data.card.title')}
              </h3>
              <span className={styles.downloadCardDescription}>
                {t('scene.impact-data.download-data.card.description')}
              </span>
              <div className={styles.buttonWrapper}>
                <Button
                  leftContent={<FaFileCsv />}
                  variant="secondary"
                  onClick={handleClickCsvDownload}
                >
                  {t('scenes.hub.impact-data.download.csv-label')}
                </Button>
                {!isLoadingImpactData && (
                  <Suspense>
                    <DownloadPdfButton
                      data={data}
                      selectedStores={selectedStoresFilter}
                      selectedDateRange={selectedDateRange}
                      selectedBusinessName={selectedBusinessName}
                    />
                  </Suspense>
                )}
              </div>
            </Card>
          </div>
        </>
      )}
    </>
  )
}

export default memo(ImpactDataScene)
