import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdCo2,
  MdDinnerDining,
  MdForest,
  MdGite,
  MdOutlineDirectionsCar,
  MdOutlineShoppingBasket,
  MdOutlineWaterDrop,
  MdRestaurant,
  MdStorefront,
} from 'react-icons/md'
import clsx from 'clsx'
import ImpactDataCard from 'components/hub/ImpactData/ImpactDataCard'
import styles from './DataGrid.module.scss'

type DataGridProps = {
  data: any
  pdfDisplayMode?: boolean
}

type OverlayLink = {
  link: string
  source: string
}

const DataGrid: React.FC<DataGridProps> = ({ data, pdfDisplayMode = false }) => {
  const { t } = useTranslation()

  const impactDataCards = {
    active_sites: {
      icon: <MdStorefront />,
      title: t('scenes.hub.impact-data.cards.active-sites-donating.title'),
      value: data.active_sites.value,
      overlayContent: t('scenes.hub.impact-data.cards.active-sites-donating.overlay'),
      overlayTitle: t('scenes.hub.impact-data.cards.active-sites-donating.overlay-title'),
    },
    food_donated: {
      icon: <MdRestaurant />,
      title: t('scenes.hub.impact-data.cards.edible-food-saved.title'),
      value: data.food_donated.value,
      unit: t(`scenes.hub.impact-data.unit.${data.food_donated.unit}`),
      overlayContent: t('scenes.hub.impact-data.cards.edible-food-saved.overlay'),
      overlayTitle: t('scenes.hub.impact-data.cards.edible-food-saved.overlay-title'),
    },
    households_fed: {
      icon: <MdGite />,
      title: t('scenes.hub.impact-data.cards.households-fed.title'),
      value: data.households_fed.value,
      overlayContent: t('scenes.hub.impact-data.cards.households-fed.overlay'),
      overlayTitle: t('scenes.hub.impact-data.cards.households-fed.overlay-title'),
    },
    meals_saved: {
      icon: <MdDinnerDining />,
      title: t('scenes.hub.impact-data.cards.meals-shared.title'),
      value: data.meals_saved.value,
      overlayContent: t('scenes.hub.impact-data.cards.meals-shared.overlay'),
      overlayTitle: t('scenes.hub.impact-data.cards.meals-shared.overlay-title'),
      overlayLinks: t('scenes.hub.impact-data.cards.meals-shared.links', {
        returnObjects: true,
      }) as OverlayLink[],
    },
    items_saved: {
      icon: <MdOutlineShoppingBasket />,
      title: t('scenes.hub.impact-data.cards.items-shared.title'),
      value: data.items_saved.value,
      overlayContent: t('scenes.hub.impact-data.cards.items-shared.overlay'),
      overlayTitle: t('scenes.hub.impact-data.cards.items-shared.overlay-title'),
    },
    co2e_avoided: {
      icon: <MdCo2 />,
      title: t('scenes.hub.impact-data.cards.co2-emissions-avoided.title'),
      value: data.co2e_avoided.value,
      unit: t(`scenes.hub.impact-data.unit.${data.co2e_avoided.unit}`),
      overlayContent: t('scenes.hub.impact-data.cards.co2-emissions-avoided.overlay'),
      overlayTitle: t('scenes.hub.impact-data.cards.co2-emissions-avoided.overlay-title'),
      overlayLinks: t('scenes.hub.impact-data.cards.co2-emissions-avoided.links', {
        returnObjects: true,
      }) as OverlayLink[],
    },
    water_saved: {
      icon: <MdOutlineWaterDrop />,
      title: t('scenes.hub.impact-data.cards.water-saved.title'),
      value: data.water_saved.value,
      unit: t(`scenes.hub.impact-data.unit.${data.water_saved.unit}`),
      overlayContent: t('scenes.hub.impact-data.cards.water-saved.overlay'),
      overlayTitle: t('scenes.hub.impact-data.cards.water-saved.overlay-title'),
      overlayLinks: t('scenes.hub.impact-data.cards.water-saved.links', {
        returnObjects: true,
      }) as OverlayLink[],
    },
    equivalent_trees: {
      icon: <MdForest />,
      title: t('scenes.hub.impact-data.cards.trees-planted.title'),
      value: data.equivalent_trees.value,
      overlayContent: t('scenes.hub.impact-data.cards.trees-planted.overlay'),
      overlayTitle: t('scenes.hub.impact-data.cards.trees-planted.overlay-title'),
      overlayLinks: t('scenes.hub.impact-data.cards.trees-planted.links', {
        returnObjects: true,
      }) as OverlayLink[],
    },
    car_off_road: {
      icon: <MdOutlineDirectionsCar />,
      title: t('scenes.hub.impact-data.cards.car-miles.title'),
      value: data.car_off_road.value,
      overlayContent: t('scenes.hub.impact-data.cards.car-miles.overlay'),
      overlayTitle: t('scenes.hub.impact-data.cards.car-miles.overlay-title'),
      overlayLinks: t('scenes.hub.impact-data.cards.car-miles.links', {
        returnObjects: true,
      }) as OverlayLink[],
    },
  }

  return (
    <div className={clsx({ [styles.pdfDisplayMode]: pdfDisplayMode })}>
      <h3>{t('scenes.hub.impact-data.section-heading.performance-impact')}</h3>
      <div className={styles.grid}>
        <ImpactDataCard
          {...impactDataCards.active_sites}
          pdfDisplayMode={pdfDisplayMode}
        />
        <ImpactDataCard
          {...impactDataCards.food_donated}
          pdfDisplayMode={pdfDisplayMode}
        />
      </div>

      <h3>{t('scenes.hub.impact-data.section-heading.social-impact')}</h3>
      <div className={styles.grid}>
        <ImpactDataCard
          {...impactDataCards.households_fed}
          pdfDisplayMode={pdfDisplayMode}
        />
        <ImpactDataCard
          {...impactDataCards.meals_saved}
          pdfDisplayMode={pdfDisplayMode}
        />
        <ImpactDataCard
          {...impactDataCards.items_saved}
          pdfDisplayMode={pdfDisplayMode}
        />
      </div>

      <h3>{t('scenes.hub.impact-data.section-heading.environmental-impact')}</h3>
      <div className={styles.grid}>
        <ImpactDataCard
          {...impactDataCards.co2e_avoided}
          pdfDisplayMode={pdfDisplayMode}
        />
        <ImpactDataCard
          {...impactDataCards.water_saved}
          pdfDisplayMode={pdfDisplayMode}
        />
        <ImpactDataCard
          {...impactDataCards.equivalent_trees}
          pdfDisplayMode={pdfDisplayMode}
        />
        <ImpactDataCard
          {...impactDataCards.car_off_road}
          pdfDisplayMode={pdfDisplayMode}
        />
      </div>
    </div>
  )
}

export default DataGrid
